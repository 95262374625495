'use client'
import { Metadata } from 'next'
import { metadataFactory } from '#/src/appConfig'
import ServerErrorStdPage from 'storefront-modules/common/components/ServerErrorStdPage'

export const metadata: Metadata = metadataFactory({
  title: 'Error',
  description: 'Unknown error',
  pathname: '/'
})

const ErrorPage = () => {
  return <ServerErrorStdPage text="Error 500." />
}

export default ErrorPage
